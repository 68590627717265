









































































































































































































































import Component from "vue-class-component";
import { getModule } from "vuex-module-decorators";
import Moment from "moment";

import BaseComponent from "@/components/base-component.vue";
import BaseIllustration from "@/components/base-illustration/base-illustration.vue";
import Loader from "@/components/loader.vue";
import { OrdersState } from "@/store/modules/orders";
import { ArchiveState } from "@/store/modules/archive";
import { SearchState } from "@/store/modules/search";
import { ProductTypes } from "@/product-types";
import Common from "@/plugins/common";

const sanitize = require("sanitize-filename");

@Component({
  name: "Order",
  components: {
    BaseIllustration,
    Loader,
  }
})
export default class Order extends BaseComponent {
  
  private readonly ordersState: OrdersState = getModule(OrdersState, this.$store);
  private readonly archiveState: ArchiveState = getModule(ArchiveState, this.$store);
  private readonly searchState: SearchState = getModule(SearchState, this.$store);

  order: any = null;
  items: any[] = [];
  photos: any[] = [];

  loading: boolean = true;
  showCodes: boolean = false;
  promocodeText: string = '';

  productTypes: any = ProductTypes;
    
  archiveId: string = "";
  archiveSize: number = 0;
  isArchiveReady: boolean = false;

  eventArchiveId: string = "";
  isEventArchiveReady: boolean = false;
  eventArchiveReloadCount: number = 0;

  reloadCount: number = 0;
  max_reload_count: number = 100;

  get orderId() {
    return this.$route.params.id || '';
  }

  get state() {
    return this.order ? this.order.state : '';
  }

  get stateClass() {
    let state = '';
    if (this.state === 'Booked') state = 'booked';
    if (this.state === 'AwaitingPayment') state = 'awaiting';
    if (this.state === 'Completed') state = 'completed';
    if (this.state === 'Canceled') state = 'canceled';
    return `summary--${state}`;
  }

  get stateText() {
    if (this.state === 'Booked') return 'Booked';
    if (this.state === 'AwaitingPayment') return this.$t('myAccount/order/stateAwaiting').toString();
    if (this.state === 'Completed') return this.$t('myAccount/order/statePaid').toString();
    if (this.state === 'Canceled') return this.$t('myAccount/order/stateCanceled').toString();
    return '';
  }

  get isCompleted() {
    return this.state === 'Completed';
  }

  get date(): string {
    return this.order ? this.order.createdOn : '';
  }

  get formattedDate(): string {
    return Moment.utc(this.date).local().format("LL");
  }

  get email(): string {
    return this.order ? this.order.customer.email : '';
  }

  get paymentType(): string {
    return 'CreditCard';
  }

  get paymentTypeText(): string {
    if (this.paymentType === 'CreditCard') return this.$t('myAccount/order/paymentCreditCard').toString();
    return '';
  }

  get currencyId(): number {
    return this.order ? this.order.currencyId : 643;
  }

  get amount(): number {
    return this.order ? this.order.amount : 0;
  }
  
  get discount(): number {
    return parseFloat((this.amount - this.totalAmount).toFixed(2));
  }

  get totalAmount(): number {
    return this.order ? this.order.totalAmount : 0;
  }

  get responseStatus() {
    return this.ordersState.responseStatus;
  }

  get productsCount(): number {
    return this.ordersState.items.length;
  }

  onBack() {
    this.$router.push({ name: 'my-orders' });
  }

  async loadOrder() {
    await this.ordersState.getOrder({ orderId: this.orderId });
    if (this.responseStatus === 401) {
      this.$emit('need-auth');
      return;
    }
    this.order = this.ordersState.order;
    
    await this.ordersState.getItems({ orderId: this.orderId });
    if (this.responseStatus === 401) {
      this.$emit('need-auth');
      return;
    }
    this.archiveSize = await this.getOrderArchiveSize(this.ordersState.items);

    this.items = await this.getGroupedBySearch();

    this.loading = false;
  }

  async getGroupedBySearch(): Promise<any[]> {
    const items: any[] = [];
    const byNumber: any[] = await this.getGroupedByNumber();
    const byFace: any[] = await this.getGroupedByFace();
    const byPromocode: any[] = await this.getGroupedByPromocode();
    
    items.push(...byNumber);
    items.push(...byFace);
    items.push(...byPromocode);

    return items;
  }

  async getGroupedByNumber(): Promise<any[]> {
    const items: any[] = await this.getItemsOfSearchType('number');
    const grouped: any[] = [];

    for (let item = 0; item < items.length; item++) {
      const eventId: string = items[item].catalog.catalogId;
      const eventName: string = items[item].catalog.title;
      const runnerNum: string = Common.getAttribute(items[item].additionalAttributes, "runnerNum");

      if (eventId === "" || eventName === "" || runnerNum === "") continue;

      if (grouped.length === 0) {          
        const i: any = { eventId: eventId, eventName: eventName, runnerNum: runnerNum, products: [] };
        i.products.push(items[item]);
        grouped.push(i);
        continue;
      } else {
        const index: number = grouped.findIndex((r: any) => r.eventId === eventId && r.runnerNum === runnerNum);
        if (index >= 0) {
          grouped[index].products.push(items[item]);
        } else {
          const i: any = { eventId: eventId, eventName: eventName, runnerNum: runnerNum, products: [] };
          i.products.push(items[item]);
          grouped.push(i);
        }
      }
    }

    for (let i = 0; i < grouped.length; i += 1) {
      const products: any[] = await this.getGroupedProducts(grouped[i].products);
      grouped[i].products = products;
    }
    
    return grouped;
  }

  async getGroupedByFace(): Promise<any[]> {
    const items: any[] = await this.getItemsOfSearchType('face');
    const grouped: any[] = [];

    for (let item = 0; item < items.length; item++) {
      const eventId: string = items[item].catalog.catalogId;
      const eventName: string = items[item].catalog.title;
      const selfieId: string = Common.getAttribute(items[item].additionalAttributes, "selfieId");
      const personId: string = Common.getAttribute(items[item].additionalAttributes, "personId");

      if (eventId === "" || eventName === "" || selfieId === "" || personId === "") continue;

      const thumbnailUrl: string = await this.getFaceThumbnail(selfieId, personId);

      if (grouped.length === 0) {          
        const i: any = {
          eventId: eventId,
          eventName: eventName, 
          selfieId: selfieId,
          personId: personId,
          thumbnailUrl: thumbnailUrl,
          products: [],
        };
        i.products.push(items[item]);
        grouped.push(i);
        continue;
      } else {
        const index: number = grouped.findIndex((r: any) => r.eventId === eventId && r.selfieId === selfieId);
        if (index >= 0) {
          grouped[index].products.push(items[item]);
        } else {
          const i: any = {
            eventId: eventId,
            eventName: eventName, 
            selfieId: selfieId,
            personId: personId,
            thumbnailUrl: thumbnailUrl,
            products: [],
          };
          i.products.push(items[item]);
          grouped.push(i);
        }
      }
    }

    for (let i = 0; i < grouped.length; i += 1) {
      const products: any[] = await this.getGroupedProducts(grouped[i].products);
      grouped[i].products = products;
    }
    
    return grouped;
  }

  async getGroupedByPromocode(): Promise<any[]> {
    const items: any[] = await this.getItemsOfSearchType('promocode');
    let grouped: any[] = [];

    for (let item = 0; item < items.length; item++) {
      const eventId: string = items[item].catalog.catalogId;
      const eventName: string = items[item].catalog.title;

      if (eventId == "" || eventName == "") continue;

      if (grouped.length == 0) {          
        let i: any = {
          eventId: eventId,
          eventName: eventName,
          promocode: 'true',
          originalPrice: items[item].originalPrice,
          totalPrice: items[item].totalPrice,
          products: [],
        };
        i.products.push(items[item]);
        grouped.push(i);
        continue;
      } else {
        const index: number = grouped.findIndex((r: any) => 
          r.eventId == eventId
        );
        if (index >= 0) {
          grouped[index].products.push(items[item]);
          grouped[index].originalPrice += items[item].originalPrice;
          grouped[index].totalPrice += items[item].totalPrice;
        } else {
          let i: any = {
            eventId: eventId,
            eventName: eventName,
            promocode: 'true',
            originalPrice: items[item].originalPrice,
            totalPrice: items[item].totalPrice,
            products: [],
          };
          i.products.push(items[item]);
          grouped.push(i);
        }
      }
    }

    for (let i = 0; i < grouped.length; i += 1) {
      const products: any[] = await this.getGroupedProducts(grouped[i].products);
      grouped[i].products = products;
    }
    
    return grouped;
  }

  async getGroupedProducts(items: any[]): Promise<any[]> {
    const products: any[] = [];
    
    const photosWithMe: any[] = this.getItemsOfType(items, this.productTypes.photos_with_me);
    for (let i = 0; i < photosWithMe.length; i++) {
      let photos: any[] = await this.getItemPhotos(photosWithMe[i], true);
      products.push({ data: photosWithMe[i], photos: photos });
    }

    const photosWithFrame: any[] = this.getItemsOfType(items, this.productTypes.photosWithFrame);
    for (let i = 0; i < photosWithFrame.length; i++) {
      let photos: any[] = await this.getItemPhotos(photosWithFrame[i], true);
      products.push({ data: photosWithFrame[i], photos: photos });
    }

    const frame: any[] = this.getItemsOfType(items, this.productTypes.frame);
    for (let i = 0; i < frame.length; i++) {
      products.push({ data: frame[i], photos: [] });
    }

    const photo: any[] = this.getItemsOfType(items, this.productTypes.photo);
    for (let i = 0; i < photo.length; i++) {
      products.push({ data: photo[i], photos: [] });
    }

    let promocodes: any[] = this.getItemsOfType(items, this.productTypes.promocode);
    promocodes = this.getGroupedByEvent(promocodes);
    for (let i = 0; i < promocodes.length; i++) {
      products.push({
        data: promocodes[i].items[0], 
        items: promocodes[i].items, 
        photos: [],
        showAll: false,
      });
    }

    return products;
  }

  async getItemsOfSearchType(type: string): Promise<any[]> {
    let result: any[] = [];

    const items: any[] = this.ordersState.items;
    for (let i = 0; i < items.length; i++) {
      const itemByFace: string = Common.getAttribute(items[i].additionalAttributes, "byFace");
      
      if (type === 'number' && itemByFace === 'false') {
        result.push(items[i]);
      }
      if (type === 'face' && itemByFace === 'true') {
        result.push(items[i]);
      }
      if (type === 'promocode' && itemByFace === '') {
        result.push(items[i]);
      }
    }
    
    return result;
  }

  async getFaceThumbnail(selfieId: string, personId: string): Promise<string> {
    if (selfieId.length === 0 || personId.length === 0) return "";

    await this.searchState.loadBySelfieId({ selfieId: selfieId });
    
    const found: any[] = this.searchState.persons.filter((r: any) => r.personId === personId);
    if (found.length === 0) return "";
    return found[0].faceUrl;
  }

  async onDownloadAll(): Promise<void> {
    if (this.loading) return;

    if (this.isArchiveReady) {
      await this.onReadyForDownload();
      return;
    }

    const items: any[] = await this.getOrderItemsForDownload();
    if (items.length === 0) return;

    await this.createArchive(items);
  }

  async onDownloadProduct(item: any): Promise<void> {
    this.photos = await this.getPhotoUrls(item);
    if (this.photos.length !== 1) return;

    await this.download(0);
  }

  async onDownloadPromocodes(products: any[], item: any): Promise<void> {
    const url = this.createCSVLink(products);
    if (!url) return;

    const link = document.createElement('a');

    let filename: string = `promocodes-${item.eventId}.csv`;
    link.href = url;
    link.setAttribute("download", filename);

    document.body.appendChild(link);
    link.click();
  }

  async onDownloadAllPhotosProduct(item: any): Promise<void> {
    if (this.loading) return;

    const items: any[] = await this.getOrderItemsForDownload(item);
    if (items.length === 0) return;

    await this.createEventArchive(items, item.orderItemId);
  }

  async onReadyForDownload(): Promise<void> {
    if (this.archiveId.length === 0) return;

    if (this.archiveState.archive && this.archiveState.archive.url) {
      const url: string = this.archiveState.archive.url;
      window.location.href = url;
    }
  }

  async onEventArchiveReadyForDownload(): Promise<void> {
    if (this.eventArchiveId.length === 0) return;

    if (this.archiveState.eventArchive && this.archiveState.eventArchive.url) {
      const url: string = this.archiveState.eventArchive.url;
      window.location.href = url;
    }
  }

  async onCopyPromocode(promocode: string): Promise<void> {
    navigator.clipboard.writeText(promocode);
  }

  async getOrderItemsForDownload(orderItem: any = null): Promise<string[]> {
    if (this.ordersState.items.length === 0) return [];

    const items: any[] = [];
    
    if (orderItem) {
      let item = {
        type: "folder",
        name: sanitize(orderItem.catalog.title),
        items: {},
      };
      const photos: any[] = await this.getItemPhotos(orderItem);
      if (photos.length === 0) return [];

      const append: any[] = photos.map((i: any) => {
        const result: any = {};
        
        if (orderItem.product.productType === this.productTypes.frame) {
          result['name'] = i.photoId + "_" + orderItem.product.photo.frameId + ".jpg";
        } else {
          result['name'] = i.photoId + ".jpg";
        }
        result['resource'] = i.resources.main;

        return result;
      });
      item.items = append;
      
      items.push(item);

      return items;
    }

    for (let i = 0; i < this.ordersState.items.length; i++) {
      const item = this.ordersState.items[i];
      const photos: any[] = await this.getItemPhotos(item);
      if (photos.length === 0) continue;

      const append: any[] = photos.map((i: any) => {
        const result: any = {};
        
        if (item.product.productType === this.productTypes.frame) {
          result['name'] = i.photoId + "_" + item.product.photo.frameId + ".jpg";
        } else {
          result['name'] = i.photoId + ".jpg";
        }
        result['resource'] = i.resources.main;

        return result;
      });
      
      items.push(...append);
    }

    return items;
  }

  async getItemPhotos(item: any, onlyPhotoWithMe: boolean = false): Promise<any[]> {
    const type: string = item.product.productType;
    if (type.length === 0) return [];

    if (onlyPhotoWithMe) {
      if (type !== this.productTypes.photos_with_me && type !== this.productTypes.photosWithFrame) {
        return [];
      }
    }

    let photos: any = [];
    if (type === this.productTypes.photos_with_me) {
      photos = Object.assign([], item.product.photos);
    } else if (type === this.productTypes.photosWithFrame) {
      photos = Object.assign([], item.product.photos);
    } else if (type === this.productTypes.promocode) {
      photos = [];
    } else {
      photos = [ item.product.photo ];
    }
    if (photos.length === 0) return [];

    return photos;
  }

  async createArchive(items: any[]): Promise<void> {

    await this.archiveState.createArchive({
      archiveId: this.orderId,
      items: items,
    });
    if (this.archiveState.archiveError) return;

    this.archiveId = this.orderId;
    
    this.isArchiveReady = false;
    this.loading = false;
    this.reloadCount = 0;
    this.reloadArchiveReady();
  }

  async createEventArchive(items: any[], archiveId: string): Promise<void> {

    await this.archiveState.createArchive({
      archiveId: archiveId,
      items: items,
    });
    if (this.archiveState.archiveError) return;

    this.eventArchiveId = archiveId;
    
    this.isEventArchiveReady = false;
    this.loading = false;
    this.eventArchiveReloadCount = 0;
    this.reloadEventArchiveReady();
  }

  async reloadArchiveReady(): Promise<void> {
    this.loading = true;

    await this.archiveState.getArchive({ archiveId: this.archiveId });
    
    if (this.archiveState.isArchiveReady) {
      this.isArchiveReady = true;
      this.loading = false;
      this.reloadCount = 0;
      this.onReadyForDownload();
      return;
    }

    this.reloadCount++;
    if (this.reloadCount < this.max_reload_count) {
      if (this.$route.name === "my-order") {
        setTimeout(async () => await this.reloadArchiveReady(), 3000);
      }
    } else {
      this.loading = false;
      this.isArchiveReady = false;
      this.reloadCount = 0;
    }
  }

  async reloadEventArchiveReady(): Promise<void> {
    this.loading = true;

    await this.archiveState.getEventArchive({ archiveId: this.eventArchiveId });
    
    if (this.archiveState.isEventArchiveReady) {
      this.isEventArchiveReady = true;
      this.loading = false;
      this.eventArchiveReloadCount = 0;
      this.onEventArchiveReadyForDownload();
      return;
    }

    this.eventArchiveReloadCount++;
    if (this.eventArchiveReloadCount < this.max_reload_count) {
      if (this.$route.name === "my-order") {
        setTimeout(async () => await this.reloadEventArchiveReady(), 3000);
      }
    } else {
      this.loading = false;
      this.isEventArchiveReady = false;
      this.eventArchiveReloadCount = 0;
    }
  }

  async getPhotoUrls(item: any): Promise<any[]> {
    const type: string = item.data.product.productType;
    if (type.length === 0) return [];

    let photos: any[] = [];
    if (type === this.productTypes.photos_with_me) {
      photos = Object.assign([], item.data.product.photos);
    } else if (type === this.productTypes.photosWithFrame) {
      photos = Object.assign([], item.data.product.photos);
    } else if (type === this.productTypes.photo) {
      photos = [ item.data.product.photo ];
    } else if (type === this.productTypes.frame) {
      photos = [ item.data.product.photo ];
    }
    if (photos.length === 0) return [];

    return photos;
  }

  async download(photoIndex: number): Promise<void> {
    await this.downloadAsFile(this.photos[photoIndex]);
  }

  async downloadAsFile(photo: any): Promise<void> {
    if (!photo) return;

    const thePhoto = photo;

    await this.archiveState.downloadFile({ url: thePhoto.resources.main });
    if (!this.archiveState.file) return;

    const blob = new Blob([this.archiveState.file], { type: "image/jpeg" });
    const url = URL.createObjectURL(blob);
    
    const link = document.createElement('a');

    let filename: string = thePhoto.photoId + ".jpg";
    if (thePhoto.frameId) {
      filename = thePhoto.photoId + "_" + thePhoto.frameId + ".jpg";
    }
    link.href = url;
    link.setAttribute("download", filename);

    document.body.appendChild(link);
    link.click();
  }

  async getOrderArchiveSize(items: any[]): Promise<number> {
    let size: number = 0;

    for (let i = 0; i < items.length; i++) {
      const photos: any[] = await this.getItemPhotos(items[i]);
      
      let noSize: boolean = false;

      for (let j = 0; j < photos.length; j++) {
        if (!photos[j].size) {
          noSize = true;
          break;
        }
        size += photos[j].size;
      }

      if (noSize) {
        size = 0;
        break;
      }
    }

    if (size != 0) {
      size = Math.round(size / Math.pow(2, 20));
    }
    
    return size;
  }

  getItemImage(item: any): string {
    const type: string = item.data.product.productType;
    if (type.length === 0) return "";

    if (type === this.productTypes.photos_with_me) {
      return "/img/all-photos.svg";
    } else if (type === this.productTypes.photosWithFrame) {
      return "/img/all-photos-in-frame.svg";
    } else if (type === this.productTypes.promocode) {
      return "/img/promocode.svg";
    } else {
      const url: string = item.data.product.photo.resources.preview;
      if (url.length === 0) return "";
      
      return url;
    }
  }

  getItemTitle(item: any): string {
    const type: string = item.product.productType;
    if (type.length === 0) return "";

    if (type === this.productTypes.photos_with_me) {
      return this.$t('productPreview/productAllPhotos').toString();
    } else if (type == this.productTypes.photosWithFrame) {
      return item.product.title;
    } else if (type === this.productTypes.photo) {
      return this.$t('productPreview/productDigitalPhoto').toString();
    } else if (type == this.productTypes.promocode) {
      return item.product.title;
    } else {
      return this.$t('searchResults/productPhotoFrame').toString();
    }
  }

  getItemEventTitle(item: any): string {
    const catalog: any = item.catalog;
    if (!catalog) return "";

    return catalog.title;
  }

  getItemsOfType(items: any[], type: string): any[] {
    if (type.length === 0) return [];

    const found: any[] = [];
    for (let i = 0; i < items.length; i++) {
      if (items[i].product.productType === type) {
        found.push(items[i]);
      }
    }
    return found;
  }

  getOriginalPrice(product: any, item: any): number {
    if (product.product.productType === this.productTypes.promocode) {
      return item.originalPrice;
    }
    return product.originalPrice;
  }

  getTotalPrice(product: any, item: any): number {
    if (product.product.productType === this.productTypes.promocode) {
      return item.totalPrice;
    }
    return product.totalPrice;
  }

  isDiscountedPrice(product: any, item: any): boolean {
    if (product.product.productType === this.productTypes.promocode) {
      return item.originalPrice !== item.totalPrice;
    }
    return product.originalPrice !== product.totalPrice;
  }

  isTwoRowsPrice(product: any, item: any): boolean {
    return this.isDiscountedPrice(product, item) && this.lang !== 'ru' && (this.smOnly || this.mdOnly);
  }

  formatAmount(amount: number, onlyInteger: boolean = true): string {
    return Common.formatAmount(amount, onlyInteger);
  }

  isItemPromocode(item: any): boolean {
    return item ? item.product.productType === this.productTypes.promocode : false;
  }

  hasOnlyPromocodeItems(): boolean {
    return this.ordersState.items.length === this.ordersState.items.filter((i: any) => i.product.productType === this.productTypes.promocode).length;
  }

  getGroupedByEvent(items: any[]): any[] {
    const grouped: any[] = [];

    for (let item = 0; item < items.length; item++) {
      const eventId: string = items[item].catalog.catalogId;
      const eventName: string = items[item].catalog.title;

      if (eventId == "" || eventName == "") continue;

      if (grouped.length == 0) {          
        let i: any = {
          eventId: eventId,
          eventName: eventName,
          items: [],
        };
        i.items.push(items[item]);
        grouped.push(i);
        continue;
      } else {
        const index: number = grouped.findIndex((r: any) => 
          r.eventId == eventId
        );
        if (index >= 0) {
          grouped[index].items.push(items[item]);
        } else {
          let i: any = {
            eventId: eventId,
            eventName: eventName,
            items: [],
          };
          i.items.push(items[item]);
          grouped.push(i);
        }
      }
    }
    
    return grouped;    
  }
  
  createCSVLink(products: any[]): string {
    let content = '';
    for (let i = 0; i < products.length; i += 1) {
      content += `${products[i].product.promocode}\n`;
    }
    if (content) {
      const params = "text/csv;charset=UTF-8";
      const blob = new Blob([content], { type: params });
      return URL.createObjectURL(blob);
    }
    return '';
  }

  async mounted() {
    await this.loadOrder();
  }
}
